<template>
    <div class="field-group" :class="{'field-group--error': !!error}">
        <div class="field-group__title" v-if="title" data-test="fieldGroupTitle">
            <small v-if="remove" class="field-group__remove"><a @click="remove()" class="link">remove</a></small>
            {{title}}
            <slot name="title-right"></slot>
        </div>
        <div class="field-group__fields">
            <slot></slot>
        </div>
        <span class="field-group--error-message" v-if="error">{{ error }}</span>
    </div>
</template>

<script>
export default {
    name: 'FieldGroup',
    props: {
        title: {
            required: false,
        },
        remove: {
            type: Function,
            required: false,
        },
        error: {
            default: null,
        },
    },

}
</script>
