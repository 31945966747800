<template>
    <div class="fill full-height-layout">
        <form @submit="saveAgreement" class="fill full-height-layout">
            <mercur-card class="fill full-height-layout agreement-edit">
                <h2 class="font-weight-normal">Add agreement</h2>

                <mercur-stepper :active-step.sync="activeStep">
                    <mercur-step id="general" title="General">
                        <div class="row">
                            <div class="mb-3" :class="layoutSizes(12,6,4,3)">
                                <field-group title="General">
                                    <mercur-input
                                        class="mt-2"
                                        v-model="$v.steps.general.agreementName.$model"
                                        :class="{'form-invalid': $v.steps.general.agreementName.$error}">
                                        Agreement name
                                        <template slot="note">
                                            <span class="form-error" v-if="!$v.steps.general.agreementName.$error">Agreement name is required</span>
                                        </template>
                                    </mercur-input>
                                </field-group>
                            </div>
                        </div>
                        <div class="row">
                            <div :class="layoutSizes(12,6,4,3)">
                                <field-group title="Catalog">
                                    <mercur-select class="mt-2" v-model="$v.steps.general.catalogUpdateType.$model">
                                        <template slot="label">Update type</template>
                                        <option value="DYNAMIC">Dynamic</option>
                                        <option value="STATIC">Static</option>
                                    </mercur-select>
                                    <mercur-input
                                        class="mt-2"
                                        v-model="$v.steps.general.catalogUpdateFrequency.$model"
                                        type="number"
                                        required
                                        v-if="$v.steps.general.catalogUpdateType.$model === 'DYNAMIC'"
                                        :class="{'form-invalid': $v.steps.general.catalogUpdateFrequency.$error}">
                                        Update frequency
                                        <span slot="suffix">days</span>
                                        <template slot="note">
                                            <span class="form-error" v-if="!$v.steps.general.catalogUpdateFrequency.required">Catalog update frequency is required when the type is set to <i>Dynamic</i></span>
                                        </template>
                                    </mercur-input>
                                </field-group>
                            </div>
                            <div :class="layoutSizes(12,6,4,3)">
                                <field-group title="Cost">
                                    <mercur-select class="mt-2" v-model="$v.steps.general.costUpdateType.$model">
                                        <template slot="label">Update type</template>
                                        <option value="DYNAMIC">Dynamic</option>
                                        <option value="STATIC">Static</option>
                                    </mercur-select>
                                    <mercur-input
                                        class="mt-2"
                                        v-model="$v.steps.general.costUpdateFrequency.$model"
                                        type="number"
                                        required
                                        v-if="$v.steps.general.costUpdateType.$model === 'DYNAMIC'"
                                        :class="{'form-invalid': $v.steps.general.costUpdateFrequency.$error}">
                                        Update frequency
                                        <span slot="suffix">days</span>
                                        <template slot="note">
                                            <span class="form-error" v-if="!$v.steps.general.costUpdateFrequency.required">Cost update frequency is required when the type is set to <i>Dynamic</i></span>
                                        </template>
                                    </mercur-input>
                                </field-group>
                            </div>
                            <div :class="layoutSizes(12,6,4,3)">
                                <field-group title="Miscellaneous">
                                    <mercur-checkbox class="mt-3" v-model="$v.steps.general.showSupplier.$model">Show supplier</mercur-checkbox>
                                    <mercur-input
                                        class="mt-3"
                                        v-model="$v.steps.general.expirationDate.$model"
                                        type="date"
                                        :class="{'form-invalid': $v.steps.general.expirationDate.$error}">
                                        Expiration date
                                        <template slot="note">
                                            <span class="form-error" v-if="!$v.steps.general.expirationDate.$error">Expiration date is required</span>
                                        </template>
                                    </mercur-input>
                                    <mercur-input class="mt-2" v-model="$v.steps.general.documentContract.$model">
                                        Document
                                    </mercur-input>
                                </field-group>
                            </div>
                        </div>
                    </mercur-step>

                    <mercur-step id="productDefinitions" title="Product selection">
                        <div class="row fill">
                            <div class="full-height-layout h-100">
                                <div class="full-height-layout fill" v-if="steps.productDefinitions.selectedProductDefinitions">
                                    <div class="text-secondary align-center">Selected product definitions</div>

                                    <span v-if="steps.productDefinitions.selectedProductDefinitions.length === 0" class="text-secondary align-center">No product definitions selected</span>

                                    <div class="fill selected-entities">
                                        <div class="flex selected-entities-item px-3 py-2 my-2" v-for="selectedProductDefinition in selectedProductDefinitions" :key="selectedProductDefinition.productHash">
                                            <span class="selected-entities-item-text">
                                                <span>
                                                    <strong>{{selectedProductDefinition.productName}}</strong>
                                                </span>
                                                <span v-if="selectedProductDefinition.margins">{{selectedProductDefinition.margins.marginType}}:
                                                    <span v-if="selectedProductDefinition.margins.marginType === 'DYNAMIC'">{{selectedProductDefinition.margins.marginValue}}%</span>
                                                    <span v-if="selectedProductDefinition.margins.marginType === 'STATIC'">{{selectedProductDefinition.margins.marginValue | asMoney}}</span>
                                                </span>
                                                <span>
                                                    <strong :class="selectedProductDefinition.agreementProductStatus === 'COMPLETE' ? 'green' : 'orange'">{{selectedProductDefinition.agreementProductStatus}}</strong>
                                                </span>
                                            </span>
                                            <span>
                                                <mercur-button class="btn btn-icon" @click="openProductCostConfiguration(selectedProductDefinition)" :disabled="!isAllowedTo('updateAgreementProduct')">
                                                    <i class="fas fa-pen"></i>
                                                </mercur-button>
                                                <mercur-button class="btn btn-icon" @click="triggerDeleteProductDefinitionDialog(selectedProductDefinition)" :disabled="!isAllowedTo('removeAgreementProduct')">
                                                    <i class="fas fa-trash"></i>
                                                </mercur-button>
                                            </span>
                                        </div>
                                    </div>
                                    <mercur-dialog :is-open.sync="deleteConfirmationDialogActive" width="45%">
                                        <div slot="header"><h3>Are you sure you want to remove this product definition from this agreement?</h3></div>
                                        <div slot="default">It is not possible to recover the details for this product definition after deleting.</div>
                                        <div slot="footer" class="text-right mt-4">
                                            <mercur-button class="btn btn-raised text-uppercase" @click="deleteConfirmationDialogActive = false">Cancel</mercur-button>
                                            <mercur-button class="btn btn-raised text-uppercase" @click="deleteProductDefinition(pendingDeleteProductDefinition)">Delete</mercur-button>
                                        </div>
                                    </mercur-dialog>
                                </div>
                            </div>
                        </div>
                    </mercur-step>

                    <mercur-step id="applications" title="Shops">
                        <div class="row fill">
                            <div class="col-6 full-height-layout h-100">
                                <div class="text-secondary align-center">Available shops</div>
                                <grid-header :quickSearch.sync="filters.applicationSearch"></grid-header>
                                <data-table
                                    class="fill border"
                                    :url="urls.applications"
                                    :options="applicationsGridOptions"
                                    :quickSearch="filters.applicationSearch"
                                    v-if="isAllowedTo('listShopApplications')"
                                ></data-table>
                            </div>
                            <div class="col-6 full-height-layout h-100">
                                <div class="text-secondary align-center">Selected shops</div>

                                <span v-if="steps.applications.selectedApplications.length === 0" class="text-secondary align-center">No shops selected</span>

                                <div class="fill selected-entities">
                                    <div class="" v-for="(selectedApplication, key) in countriesGroupedByApplications" :key="key">
                                        <div class="selected-entities-item selected-entities-item--application p-3">
                                            <span class="selected-entities-item-text">
                                                <span>{{selectedApplication.applicationName}}</span>
                                                <span class="text-secondary">{{selectedApplication.applicationId}}</span>
                                            </span>
                                        </div>
                                        <div
                                            v-for="(countryApplication, countryApplicationKey) in selectedApplication.countryApplications" :key="countryApplicationKey"
                                            class="selected-entities-item flex ml-4 my-1 px-2"
                                        >
                                            <div class="flex justify-content-between align-items-center w-100">
                                                <em :class="{'error': !isValidCountryCodeForAvailableCountries(countryApplication.countryCode) === null}">
                                                    {{getCountryNameForShortCode(countryApplication.countryCode)}}
                                                    <strong :class="countryApplication.agreementShopStatus === 'COMPLETE' ? 'green' : 'orange'">
                                                        {{countryApplication.agreementShopStatus}}
                                                    </strong>
                                                </em>
                                                <mercur-button class="btn btn-icon btn-mini" @click="removeSelectedApplication(countryApplication)" :disabled="!isAllowedTo('removeAgreementShop')">
                                                    <i class="fas fa-trash"></i>
                                                </mercur-button>
                                            </div>
                                        </div>
                                        <mercur-button class="btn selected-entities-item border-0 ml-4 mb-3 w-100 text-left h-50" @click="openAddSelectApplicationDialog(selectedApplication)" :disabled="!isAllowedTo('addAgreementShop')">
                                            Add country +
                                        </mercur-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mercur-step>

                    <mercur-step id="confirmation" title="Confirmation">
                        <h2 class="font-weight-normal">Confirmation</h2>
                        <p>The agreement is ready to be processed. Click the generate button to generate the agreement with the selected products.</p>
                        <div>
                            <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="generateAgreement" :disabled="!isAllowedTo('updateAgreement') || !isAllowedTo('updateAgreementProduct')">Generate</mercur-button>
                        </div>
                    </mercur-step>
                </mercur-stepper>
                <div class="text-right">
                    <template v-if="activeStep === 'productDefinitions' || activeStep === 'applications'">
                        <mercur-button @click="nextStep" :disabled="$v.steps[activeStep].$invalid" class="btn btn-raised btn-yellow text-uppercase">Continue</mercur-button>
                    </template>
                    <template v-else-if="activeStep !== 'confirmation'">
                        <mercur-button class="btn btn-raised text-uppercase mr-3" @click="saveAgreement">Save</mercur-button>
                        <mercur-button @click="saveAgreementAndNextStep" :disabled="$v.steps[activeStep].$invalid" class="btn btn-raised btn-yellow text-uppercase">Save and continue</mercur-button>
                    </template>
                </div>
            </mercur-card>
        </form>

        <agreement-product-costs :dialog-open.sync="addProductCostsDialogOpen" v-bind="addProductCostsDialogParams" :agreement-id="agreementId" @save="handleProductDefinitionSave"></agreement-product-costs>

        <mercur-dialog :is-open.sync="addSelectApplicationDialogOpen" width="40%">
            <div slot="header"><h3>Select shop countries</h3></div>
            <div slot="default" v-if="addSelectApplicationParams">
                <p>Select the countries that are applicable for <b>{{addSelectApplicationParams.applicationName}}</b>.</p>
                <pretty-select
                    v-if="availableCountries && addSelectApplicationParams.countryCodes"
                    label="countryName"
                    v-model="addSelectApplicationParams.countryCodes"
                    placeholder="Select a country"
                    :multiple="true"
                    :options="availableCountries.filter(country => !addSelectApplicationParams.countryCodes.includes(country.countryShortCode))"
                    :reduce="country => country.countryShortCode"
                    :select-on-tab="true"
                    @keypress.enter.prevent="() => {}"
                ></pretty-select>
            </div>

            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="addSelectApplicationDialogOpen = false">Close</mercur-button>
                <mercur-button class="btn btn-raised text-uppercase" :disabled="addSelectApplicationLoading || !addSelectApplicationParams || !addSelectApplicationParams.countryCodes || !addSelectApplicationParams.countryCodes.length" @click="addSelectedApplication(addSelectApplicationParams)">
                    Add shop
                </mercur-button>
            </div>
            <mercur-progress-bar indeterminate v-if="addSelectApplicationLoading"></mercur-progress-bar>
        </mercur-dialog>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
    required,
    requiredIf,
    minLength,
} from 'vuelidate/lib/validators'

import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import FieldGroup from '@/components/utils/FieldGroup'
import AgreementProductCosts from '@/components/agreements/AgreementProductCosts'
import regions from 'country-region-data'
import PrettySelect from '../../components/utils/PrettySelect'

export default {
    name: 'AgreementAdd',
    components: { AgreementProductCosts, FieldGroup, GridHeader, DataTable, PrettySelect },
    mixins: [validationMixin],
    data () {
        return {
            activeStep: 'general',
            addProductCostsDialogOpen: false,
            addProductCostsDialogParams: null,
            deleteConfirmationDialogActive: false,
            addSelectApplicationDialogOpen: false,
            addSelectApplicationParams: null,
            addSelectApplicationLoading: false,
            availableCountryCodes: null,
            urls: {
                applications: CONFIG.API.ROUTES.AGREEMENTS.APPLICATIONS.OVERVIEW,
                productDefinitions: CONFIG.API.ROUTES.AGREEMENTS.PRODUCTS.OVERVIEW,
            },
            filters: {
                applicationSearch: '',
                productDefinitionsSearch: '',
            },
            applicationsGridOptions: {
                columns: {
                    '': {
                        field: 'selected',
                        width: 70,
                        cellRendererFramework: 'CheckboxCell',
                        cellRendererParams: (params) => {
                            return {
                                getValue: () => {
                                    return this.steps.applications.selectedApplications.some(selectedApplication => selectedApplication.applicationId === params.data.applicationId)
                                },
                                setValue: (value) => {
                                    if (value) {
                                        this.openAddSelectApplicationDialog(params.data)
                                        return
                                    }

                                    this.removeSelectedApplication(params.data)
                                },
                                disabled: (params) => {
                                    return params.getValue()
                                },
                            }
                        },
                    },
                    'Name': {
                        field: 'applicationName',
                        sortable: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                },
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
                quickSearchColumns: ['applicationName'],
            },
            productDefinitionsGridOptions: {
                columns: {
                    'Name': {
                        field: 'productNames',
                        sortable: true,
                    },
                    'Attributes': {
                        field: 'attributes',
                        sortable: true,
                        tooltipField: 'attributes',
                    },
                    'Hash': {
                        field: 'productHash',
                        sortable: true,
                        hide: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                    'productName': {
                        field: 'productName',
                        hide: true,
                    },
                    'productHash': {
                        field: 'productHash',
                        hide: true,
                    },
                    'supplierId': {
                        field: 'supplierId',
                        hide: true,
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-plus',
                        onClick: params => {
                            this.openProductCostConfiguration(params.data)
                        },
                        disabled: params => {
                            if ((!this.agreementId && !this.isAllowedTo('addAgreementProduct')) || (this.agreementId && !this.isAllowedTo('updateAgreementProduct'))) {
                                return true
                            }
                            return !!this.steps.productDefinitions.selectedProductDefinitions[params.data.productHash]
                        },
                    },
                ],
                quickSearchColumns: ['productName', 'productHash'],
                autoHeight: true,
            },
            steps: {
                general: {
                    agreementName: '',
                    catalogUpdateType: 'DYNAMIC',
                    catalogUpdateFrequency: '1',
                    costUpdateType: 'DYNAMIC',
                    costUpdateFrequency: '1',
                    expirationDate: null,
                    documentContract: '',
                    showSupplier: false,
                },
                productDefinitions: {
                    selectedProductDefinitions: {},
                },
                applications: {
                    selectedApplications: [],
                },
                confirmation: ['general', 'applications', 'productDefinitions'],
            },
        }
    },
    validations () {
        return {
            steps: {
                general: {
                    agreementName: { required },
                    catalogUpdateType: {},
                    catalogUpdateFrequency: {
                        required: requiredIf(function (values) {
                            return values.catalogUpdateType === 'DYNAMIC'
                        }),
                    },
                    costUpdateType: {},
                    costUpdateFrequency: {
                        required: requiredIf(function (values) {
                            return values.costUpdateType === 'DYNAMIC'
                        }),
                    },
                    expirationDate: {
                        required,
                    },
                    documentContract: {},
                    showSupplier: {},
                },
                productDefinitions: {
                    selectedProductDefinitions: {
                        minLength: minLength(1),
                        required,
                    },
                },
                applications: {
                    selectedApplications: {
                        minLength: minLength(1),
                        required,
                        $each: {
                            countryCodes: {
                                available: (value) => {
                                    return this.isValidCountryCodeForAvailableCountries(value)
                                },
                            },
                        },
                    },
                },
                confirmation: {},
            },
        }
    },
    computed: {
        agreementId: {
            get () {
                return this.$route.params.agreementId || null
            },
            set (value) {
                this.$router.replace({
                    name: 'AgreementEdit',
                    params: {
                        agreementId: value,
                    },
                })
            },
        },
        stepGeneralDone: {
            get () {
                return this.getDoneForStepName('general')
            },
            set (value) { },
        },

        stepApplicationsDone: {
            get () {
                return this.getDoneForStepName('applications')
            },
            set (value) { },
        },

        stepApplicationsError () {
            return this.getErrorForStepName('applications')
        },
        stepProductDefinitionsDone: {
            get () {
                return this.getDoneForStepName('productDefinitions')
            },
            set (value) { },
        },

        stepProductDefinitionsError () {
            return this.getErrorForStepName('productDefinitions')
        },
        stepConfirmationDone: {
            get () {
                return this.getDoneForStepName('confirmation')
            },
            set (value) { },
        },

        stepConfirmationError () {
            return this.getErrorForStepName('confirmation')
        },

        selectedProductDefinitions () {
            return Object.values(this.steps.productDefinitions.selectedProductDefinitions).sort((a, b) => a.productName.toLowerCase().localeCompare(b.productName.toLowerCase()))
        },
        availableCountries () {
            if (this.availableCountryCodes === null) {
                return null
            }
            return regions.filter(region => this.availableCountryCodes.includes(region.countryShortCode))
        },
        countriesGroupedByApplications () {
            return this.steps.applications.selectedApplications.reduce((applications, application) => {
                const key = `${application.applicationName}_${application.applicationId}`
                if (!applications[key]) {
                    applications[key] = {
                        ...application,
                        countryApplications: [],
                    }
                }

                applications[key].countryApplications.push(application)

                return applications
            }, {})
        },
    },

    watch: {
        'steps.productDefinitions.selectedProductDefinitions': {
            handler (definitions) {
                const productHashes = Object.values(definitions).map(product => product.productHash)
                if (productHashes.length === 0) {
                    this.setAvailableCountryShortCodes(regions)
                    return
                }

                const payload = {
                    ...CONFIG.API.REQUEST_DEFAULT,
                    productHashes,
                }
                if (!this.isAllowedTo('getProductsShipsToList')) {
                    return
                }
                this.addJob(payload)

                this.$api.post(CONFIG.API.ROUTES.AGREEMENTS.PRODUCTS.GET_SHIPS_TO, payload).then(({ data }) => {
                    this.setAvailableCountryShortCodes(data.data.countries)
                }).catch(data => {
                    this.$root.$emit('notification:global', {
                        message: `Getting country list for products failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                }).finally(() => {
                    this.finishJob(payload)
                })
            },
            immediate: true,
        },
    },

    methods: {
        setAvailableCountryShortCodes (countryShortCodes = []) {
            this.availableCountryCodes = countryShortCodes.map(({ countryShortCode }) => countryShortCode)
        },
        openAddSelectApplicationDialog (application) {
            this.addProductCostsDialogOpen = false
            this.addSelectApplicationDialogOpen = true
            this.addSelectApplicationParams = {
                ...application,
                countryCodes: [],
            }
            this.addSelectApplicationLoading = false
        },
        addSelectedApplication (application) {
            if (!this.isAllowedTo('addAgreementShop')) {
                this.$root.$emit('notification:global', {
                    message: `Not allowed to do this action`,
                })
                return
            }
            this.addSelectApplicationDialogOpen = false
            application.countryCodes.forEach(countryShortCode => {
                this.steps.applications.selectedApplications.unshift({
                    ...application,
                    countryCode: countryShortCode,
                })
            })
            const url = CONFIG.API.ROUTES.AGREEMENTS.APPLICATIONS.ADD.replace('{agreementId}', this.agreementId)
            this.addJob(application)
            this.$api.post(url, application).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Adding shop completed.`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Adding shop failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(application)
            })
        },

        removeSelectedApplication (application) {
            const url = CONFIG.API.ROUTES.AGREEMENTS.APPLICATIONS.REMOVE.replace('{agreementId}', this.agreementId)
            this.steps.applications.selectedApplications = this.steps.applications.selectedApplications.filter(selectedApplication => {
                return (
                    selectedApplication.applicationId !== application.applicationId ||
                    selectedApplication.applicationName !== application.applicationName ||
                    selectedApplication.countryCode !== application.countryCode
                )
            })
            this.addJob(application)
            this.$api.post(url, application).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Removing shop completed.`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Removing shop failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(application)
            })
        },
        isValidCountryCodeForAvailableCountries (shortCode) {
            if (this.availableCountryCodes === null) {
                return null
            }
            return this.availableCountryCodes.includes(shortCode)
        },
        getCountryNameForShortCode (shortCode) {
            const country = this.getCountryByShortCodeFromAvailableCountries(shortCode)

            if (!country || !this.isValidCountryCodeForAvailableCountries(shortCode)) {
                const allCountryReference = regions.find(region => region.countryShortCode === shortCode)

                return `INVALID: ${allCountryReference ? allCountryReference.countryName : shortCode}`
            }

            if (country.isLoading) {
                return '...'
            }

            return country.countryName || shortCode
        },
        getCountryByShortCodeFromAvailableCountries (shortCode) {
            if (!this.availableCountryCodes) {
                return null
            }

            return this.availableCountries.find(({ countryShortCode }) => countryShortCode === shortCode)
        },
        triggerDeleteProductDefinitionDialog (product) {
            this.pendingDeleteProductDefinition = product
            this.addProductCostsDialogOpen = false
            this.deleteConfirmationDialogActive = true
        },
        openProductCostConfiguration (product) {
            this.addProductCostsDialogOpen = true
            this.addProductCostsDialogParams = product
        },
        getDoneForStepName (stepName) {
            return this.$v && this.$v.steps && this.$v.steps[stepName] && !this.$v.steps[stepName].$invalid
        },
        getErrorForStepName (stepName) {
            return this.$v && this.$v.steps && this.$v.steps[stepName] && this.$v.steps[stepName].$anyError ? 'Has one or more errors' : ''
        },
        generateAgreement () {
            if (!this.isAllowedTo('updateAgreement') || !this.isAllowedTo('updateAgreementProduct')) {
                return
            }
            const url = CONFIG.API.ROUTES.AGREEMENTS.GENERATE.replace('{agreementId}', this.agreementId)
            this.loading = true
            this.addJob(url)

            this.$api.post(url, this.steps).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Process for generating agreement has been scheduled.`,
                })

                this.$router.push({
                    name: 'AgreementsView',
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Scheduling agreement processing failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        saveAgreement () {
            const isNew = this.agreementId === null
            const url = isNew ? CONFIG.API.ROUTES.AGREEMENTS.ADD : CONFIG.API.ROUTES.AGREEMENTS.UPDATE.replace('{agreementId}', this.agreementId)

            if (isNew && !this.isAllowedTo('createAgreement')) {
                return
            }

            if (!isNew && !this.isAllowedTo('updateAgreement')) {
                return
            }

            this.addJob(url)
            const data = {
                ...this.steps.general,
            }

            if (isNew) {
                data.agreementStatus = 'DRAFT'
            }

            return this.$api.post(url, data).then(({ data }) => {
                if (data.data.agreementId) {
                    this.agreementId = data.data.agreementId
                }
                this.$root.$emit('notification:global', {
                    message: `Saving agreement completed.`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving agreement failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        saveAgreementAndNextStep () {
            this.saveAgreement().then(() => {
                this.nextStep()
            })
        },
        nextStep () {
            if (this.activeStep === 'general') {
                this.activeStep = 'productDefinitions'
                this.steps.general.done = true

                return
            }

            if (this.activeStep === 'productDefinitions') {
                this.activeStep = 'applications'
                this.steps.general.done = true
                this.steps.productDefinitions.done = true

                return
            }

            if (this.activeStep === 'applications') {
                this.activeStep = 'confirmation'
                this.steps.general.done = true
                this.steps.applications.done = true
            }
        },
        handleProductDefinitionSave (productDefinition) {
            this.$set(this.steps.productDefinitions.selectedProductDefinitions, productDefinition.productHash, productDefinition)
        },
        deleteProductDefinition (product) {
            if (!this.isAllowedTo('removeAgreementProduct')) {
                return
            }
            const url = CONFIG.API.ROUTES.AGREEMENTS.PRODUCTS.REMOVE.replace('{agreementId}', this.agreementId).replace('{agreementProductId}', product.agreementProductId)
            this.addJob(url)
            this.$api.post(url, product).then(() => {
                this.$delete(this.steps.productDefinitions.selectedProductDefinitions, product.productHash)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting agreement failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        getCurrentAgreementDetails () {
            if (!this.isAllowedTo('getAgreementById')) {
                this.$root.$emit('notification:global', {
                    message: `Not allowed to get agreement by id`,
                    type: 'error',
                })
                return
            }

            if (!this.agreementId) {
                return
            }

            const agreementDataUrl = CONFIG.API.ROUTES.AGREEMENTS.GET.replace('{agreementId}', this.agreementId)
            this.addJob(agreementDataUrl)
            this.$api.get(agreementDataUrl).then(({ data }) => {
                Object.entries(data.data).forEach(([key, value]) => {
                    if (this.steps.general.hasOwnProperty(key)) {
                        this.$set(this.steps.general, key, value)
                    }
                })

                this.steps.applications.selectedApplications = [
                    ...data.data.agreementShops,
                ]
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting agreement failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(agreementDataUrl)
            })

            const selectedProductsUrl = CONFIG.API.ROUTES.AGREEMENTS.GET_PRODUCTS.replace('{agreementId}', this.agreementId)
            this.addJob(selectedProductsUrl)
            this.$api.post(selectedProductsUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                data.data.items.forEach(productDefinition => {
                    this.$set(this.steps.productDefinitions.selectedProductDefinitions, productDefinition.productHash, {
                        ...productDefinition,
                        ...productDefinition.agreementProductConfig,
                    })
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting selected products failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(selectedProductsUrl)
            })
        },
    },
    created () {
        this.getCurrentAgreementDetails()
    },
}
</script>

<style lang="scss" scoped>
    .selected-entities {
        margin-top: 30px;
        padding-left: 6px;
        padding-right: 6px;

        &-item {
            background-color: #f6f6f6;
            border-left: 2px solid #bbb;
            font-size: 13px;

            &--application {
                position: sticky;
                top: -4px;
                z-index: 4;
            }

            &-text {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                line-height: 1.25em;
                white-space: nowrap;
            }
        }
    }
</style>
