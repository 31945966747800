<template>
    <mercur-dialog :is-open.sync="dialogOpen" width="80%" height="90vh">
        <div slot="header" class="flex align-items-center" v-if="productNames">
            <h3>Product margins for {{productNames}}</h3>
            <mercur-tooltip>
                <i class="fas fa-question-circle text-grey ml-1"></i>
                <template slot="label">In the Platform Margin section you can set the margin that should be applied for all product variation (sku's). You can also override the margin type and value on a variation level.</template>
            </mercur-tooltip>
        </div>
        <div slot="default" class="full-height-layout fill" v-if="$v">
            <div class="global-values">
                <div class="row">
                    <div class="col-8">
                        <mercur-input
                            v-model="$v.productNameLocal.$model"
                            data-test="AgreementProductionCostProductName"
                            :class="{'form-invalid': $v.productNameLocal.$error}"
                            class="w-75">
                            Product name
                            <template slot="note">
                                <span class="form-error" v-if="!$v.productNameLocal.required">Name is required</span>
                            </template>
                        </mercur-input>

                        <mercur-input data-test="AgreementProductionCostSearchFilterInput" v-model="filters.search" class="mb-0">
                            Search by sku
                        </mercur-input>
                    </div>
                    <div class="col-4">
                        <mercur-select data-test="AgreementProductionCostMarginType" v-model="marginType">
                            <template slot="label">Margin type</template>
                            <option value="DYNAMIC">Dynamic</option>
                            <option value="STATIC">Static</option>
                        </mercur-select>
                        <mercur-input
                            v-model="$v.marginValue.$model"
                            data-test="AgreementProductionCostProductMarginValue"
                            type="number"
                            step="0.01"
                            :class="{'form-invalid': $v.marginValue.$error}">
                            Margin value
                            <template slot="prefix" v-if="marginType === 'DYNAMIC'">%</template>
                            <template slot="suffix" v-if="marginType === 'STATIC'">€</template>
                            <template slot="note">
                                <span class="form-error" v-if="!$v.marginValue.required">Margin value is required</span>
                            </template>
                        </mercur-input>
                    </div>
                </div>
            </div>

            <data-table
                class="border"
                :options="gridOptions"
                :url="url"
                :quick-search="filters.search"
                v-if="isAllowedTo('listAllSkusByProductHash')"
            ></data-table>
        </div>

        <div slot="footer" class="flex justify-content-between w-100" v-if="$v">
            <div>
                <mercur-button class="btn btn-raised text-uppercase" @click="close">Cancel</mercur-button>
            </div>
            <div>
                <mercur-button data-test="AgreementProductionCostSaveProductButton" class="btn btn-raised text-uppercase" @click="save" :disabled="loading || $v.$invalid">Save</mercur-button>
                <mercur-button data-test="AgreementProductionCostSaveCloseProductButton" class="btn btn-raised btn-yellow text-uppercase" @click="saveAndClose" :disabled="loading || $v.$invalid">Save and close</mercur-button>
            </div>
        </div>
        <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
    </mercur-dialog>
</template>

<script>
import CONFIG from '@root/config'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import DataTable from '@/components/DataTable'

export default {
    name: 'AgreementProductCosts',
    components: { DataTable },
    mixins: [validationMixin],
    props: {
        productNames: {},
        productName: {},
        productHash: {},
        agreementId: {},
        dialogOpen: {},
        agreementProductId: {},
        productConfigurationTemplateId: {},
        categoryId: {},
        margins: {
            default: () => ({}),
        },
        selections: {
            default: () => ({}),
        },
    },
    data () {
        return {
            marginType: null,
            marginValue: null,
            allSelected: true,
            selectionExceptions: null,
            marginTypeExceptions: null,
            marginValueExceptions: null,
            productNameLocal: null,
            loading: false,
            activeTab: null,
            url: null,
            gridApi: null,
            filters: {
                search: '',
            },
            gridOptions: {
                sortModel: [{
                    colId: 'sku',
                    sort: 'asc',
                }],
                columns: {
                    '': {
                        field: 'selected',
                        width: 70,
                        maxWidth: 70,
                        cellRendererFramework: 'CheckboxCell',
                        cellRendererParams: (params) => {
                            return {
                                getValue: () => {
                                    if (this.allSelected) {
                                        return !this.isSelectionException(params.data)
                                    }

                                    return this.isSelectionException(params.data)
                                },
                                setValue: (value) => {
                                    if ((this.allSelected && value) || (!this.allSelected && !value)) {
                                        this.removeSelectionException(params.data)
                                    } else {
                                        this.addSelectionException(params.data)
                                    }
                                },
                            }
                        },
                        headerComponentFramework: 'CheckboxCell',
                        headerComponentParams: () => {
                            return {
                                disabled: this.loading,
                                setValue: newValue => {
                                    this.allSelected = newValue
                                    this.selectionExceptions = []
                                },
                                getValue: () => this.allSelected,
                            }
                        },
                    },
                    'SKU': {
                        field: 'sku',
                        sort: 'asc',
                        sortable: true,
                    },
                    'Margin type': {
                        cellEditor: 'agRichSelectCellEditor',
                        cellEditorParams: {
                            values: ['inherit', 'DYNAMIC', 'STATIC'],
                        },
                        editable: true,
                        cellClassRules: {
                            'italic': ({ value }) => value === 'inherit',
                        },
                        valueGetter: param => {
                            if (this.isMarginTypeException(param.data)) {
                                return this.getMarginTypeException(param.data)
                            }
                            return 'inherit'
                        },
                        valueSetter: params => {
                            this.redrawRows()
                            if (params.newValue === 'inherit') {
                                return this.removeMarginTypeException(params.data)
                            }

                            this.addMarginTypeException(params.data, params.newValue)
                        },
                    },
                    'Margin value': {
                        editable: true,
                        cellClassRules: {
                            'italic': ({ value }) => value === '',
                        },
                        valueGetter: param => {
                            if (this.isMarginValueException(param.data)) {
                                return this.getMarginValueException(param.data)
                            }

                            return ''
                        },

                        valueSetter: params => {
                            if (params.newValue) {
                                return this.addMarginValueException(params.data, params.newValue)
                            }

                            this.removeMarginValueException(params.data)
                        },

                        valueFormatter: params => {
                            if (params.value === '') {
                                return 'inherit'
                            }

                            const marginType = this.isMarginTypeException(params.data) ? this.getMarginTypeException(params.data) : this.marginType
                            if (marginType === 'DYNAMIC') {
                                return `${params.value}%`
                            }

                            if (marginType === 'STATIC') {
                                return this.$options.filters.asMoney(params.value)
                            }
                        },
                    },
                },
                onGridReady: params => {
                    this.gridApi = params.api
                },
                suppressCellSelection: false,
                enableRangeSelection: true,
                quickSearchColumns: ['sku'],
            },
        }
    },
    computed: {
        productDefinitionSelections () {
            return {
                allSelected: this.allSelected,
                selectionExceptions: this.selectionExceptions,
            }
        },
        productDefinitionMargins () {
            return {
                marginType: this.marginType,
                marginValue: this.marginValue,
                marginTypeExceptions: this.marginTypeExceptions,
                marginValueExceptions: this.marginValueExceptions,
            }
        },
    },
    validations: {
        productNameLocal: { required },
        marginType: { required },
        marginValue: { required },
    },

    methods: {
        addSelectionException (data) {
            this.selectionExceptions.push(data.sku)
        },
        removeSelectionException (data) {
            this.selectionExceptions.splice(this.selectionExceptions.indexOf(data.sku), 1)
        },
        isSelectionException (data) {
            return this.selectionExceptions.includes(data.sku)
        },

        removeMarginTypeException (data) {
            this.$delete(this.marginTypeExceptions, data.sku)
        },
        addMarginTypeException (data, value) {
            this.$set(this.marginTypeExceptions, data.sku, value)
        },
        isMarginTypeException (data) {
            return !!this.marginTypeExceptions[data.sku]
        },
        getMarginTypeException (data) {
            return this.marginTypeExceptions[data.sku]
        },

        removeMarginValueException (data) {
            this.$delete(this.marginValueExceptions, data.sku)
        },
        addMarginValueException (data, value) {
            this.$set(this.marginValueExceptions, data.sku, value)
        },
        isMarginValueException (data) {
            return !!this.marginValueExceptions[data.sku]
        },
        getMarginValueException (data) {
            return this.marginValueExceptions[data.sku]
        },

        save () {
            const data = {
                productHash: this.productHash,
                productNames: this.productNames,
                productName: this.productNameLocal,
                selections: this.productDefinitionSelections,
                margins: this.productDefinitionMargins,
                categoryId: this.categoryId,
                productConfigurationTemplateId: this.productConfigurationTemplateId,
                agreementProductStatus: 'DRAFT',
            }

            if (this.agreementProductId) {
                if (!this.isAllowedTo('updateAgreementProduct')) {
                    this.$root.$emit('notification:global', {
                        message: `Not allowed to do this action`,
                    })
                    return
                }
                data.agreementProductId = this.agreementProductId
            } else if (!this.isAllowedTo('addAgreementProduct')) {
                this.$root.$emit('notification:global', {
                    message: `Not allowed to do this action`,
                })
                return
            }

            const url = CONFIG.API.ROUTES.AGREEMENTS.PRODUCTS.ADD.replace('{agreementId}', this.agreementId).replace('{productHash}', this.productHash)

            this.addJob(url)
            this.loading = true
            return this.$api.post(url, data).then(response => {
                this.$root.$emit('notification:global', {
                    message: `Successfully saved product.`,
                })
                data.agreementProductId = response.data.data.agreementProductId
                this.$emit('save', data)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving product failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        close () {
            this.$emit('update:dialogOpen', false)
        },
        saveAndClose () {
            this.save().then(() => {
                this.close()
            })
        },
        reset () {
            this.selectionExceptions = this.selections.selectionExceptions || []
            this.allSelected = !!this.selections.selectionExceptions || true
            if (this.margins.marginTypeExceptions && !Array.isArray(this.margins.marginTypeExceptions)) {
                this.marginTypeExceptions = this.margins.marginTypeExceptions
            } else {
                this.marginTypeExceptions = {}
            }

            if (this.margins.marginValueExceptions && !Array.isArray(this.margins.marginValueExceptions)) {
                this.marginValueExceptions = this.margins.marginValueExceptions
            } else {
                this.marginValueExceptions = {}
            }

            this.marginType = this.margins.marginType || 'STATIC'
            this.marginValue = this.margins.marginValue || 0
            this.productNameLocal = this.productName || ''
            this.url = CONFIG.API.ROUTES.AGREEMENTS.PRODUCTS.SKUS.replace('{productHash}', this.productHash)
            this.$v.$reset()
        },
        redrawRows () {
            setTimeout(() => {
                if (!this.gridApi) {
                    return
                }
                this.gridApi.redrawRows()
            }, 0)
        },
    },

    watch: {
        dialogOpen () {
            this.reset()
        },
        marginType () {
            this.redrawRows()
        },
    },
    created () {
        this.reset()
    },
}
</script>

<style lang="scss" scoped>
    .global-values {
        padding-right: 20px;
        padding-left: 20px;
    }
</style>
